/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';

@import '@fontsource/poppins/300.css';
@import '@fontsource/poppins/400.css';
@import '@fontsource/poppins/500.css';
@import '@fontsource/poppins/600.css';
@import '@fontsource/poppins/700.css';

:root {
  --medium-gray: #747474;
}

body {
  min-height: 100vh;
  background-color: #e8eef8;
  font-size: 0.775rem;
  font-family: 'Poppins';
}

.content-box {
  background-color: #fff;
  border: #e7e7e7 1px solid;
  padding: 1.6rem 0.8rem;
}

.title {
  font-size: 0.875rem;
  font-weight: bold;
}

button {
  background-color: #fff;
  border: #e7e7e7 1px solid;
}

h1 {
  font-size: 24px;
  font-weight: 600;
}

h2 {
  font-size: 16px;
  font-weight: 600;
}

h3 {
  font-size: 16px;
  font-weight: 600;
}

h4 {
  font-size: 12px;
  font-weight: 400;
}

.text-bold {
  font-weight: bold;
}
.text-medium {
  font-weight: 500;
}

.pointer {
  cursor: pointer;
}

.no-wrap {
  white-space: nowrap;
}


app-dashboard {
  > .container {
    min-width: 1024px;
  }
}
